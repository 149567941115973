<template>
    <div>
        <a href="#"
           @click.prevent="$root.$children[0].openModal('article-modal', {id: row.item.id,tab:'stock'}, refreshTable)"
        >{{ row.item.sku }}</a>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>